.bg-color-main { 
    background-color:rgb(0,43,59);
}

.fish{
    background-image:url(../img/fish-bg.png);
    background-size: 100%;
    background-attachment: fixed;
    background-position: bottom;
    background-repeat:no-repeat;
    z-index: -100;
}

.about-text{
    width:55% !important; 
    z-index: 1 !important;
    text-transform:uppercase !important;
    text-align: justify !important; 
}